@import './global.scss';

.app::-webkit-scrollbar {
  display: none;
}

.app {
  height: 100vh;
  background-size: 400% 400%;
  z-index: 0;
  

  .vidContainer {
    width: 100vw;
    height: 100%;
    position: absolute;
    overflow: hidden;
    z-index: 0;

    .vid {
      width: 100%;
      height: 100%;
      opacity: 0.15;
    }
  }

  .sections {
    width: 100vw;
    top: 0px;
    position: relative;
    background-color: transparent;
    position: relative;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none;
    z-index: 1;

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100vw;
      height: 100vh;
      scroll-snap-align: start;
    }
  }
}
