@import '../../global.scss';

.projects {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh !important;
  overflow: hidden;
  transition: all 0.7s ease;

  .carousel {
    height: 100%;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: all 0.7s ease;
    .carousel-inner{
      height: 100%;
    }

    .item {
      height: 100%;
      overflow: hidden;

      .bgContainer {
        width: 100vw;

        .item-bg {
          flex-direction: row;
          justify-content: center;
          position: absolute;
          z-index: -1;
          opacity: 0.1;
          width: 100vw;
          @include mobile{
            width: 200% !important;
            height: 100%;
          }
        }
      }

      .logoContainer {
        left: 350px;
        width: 250px;
        position: inherit;
        justify-content: center;
        z-index: 2;
        top: 162px;

        @include mobile {
          left: 150px;
        }

        .logo {
          width: 50%;
        }
      }

      .caption {
        justify-content: center;
        position: relative;

        z-index: 1;

        @include mobile {
          left: 10px;
        }

        a {
          text-decoration: none;
          z-index: 2;

          h3 {
            color: $header;
            font-weight: 700;
            cursor: pointer;
            font-size: 55px;

            &:hover {
              font-weight: 900;
            }
            @include mobile{
                font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

.focus-in-contract-bck {
  -webkit-animation: focus-in-contract-bck 2s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract-bck 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.slide-in-blurred-left {
  -webkit-animation: slide-in-blurred-left 2.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: slide-in-blurred-left 2.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
    transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.fade-in-fwd {
  -webkit-animation: fade-in-fwd 1.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 1.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-20 0:55:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
