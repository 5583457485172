@import '../../global.scss';

.latest {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.1);
  @include mobile {
    flex-direction: column;
  }

  .latestvid {
    iframe {
      overflow: hidden;
      width: 560px;
      height: 560px;
      @include mobile {
        width: 100vw;
        height: 49vh;
      }
    }
  }
  .latestep {
    iframe {
      overflow: hidden;
      width: 560px;
      height: 560px;
      @include mobile {
        width: 100vw;
        height: 49vh;
      }
    }
  }
}
