@import '../../global.scss';

.intro {
  display: flex;
  height: 100%;

  @include mobile {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .left {
    flex: 0.67;
    overflow: hidden;
    .imgContainer {
      width: 1200px;
      height: 1200px;
      background-color: $accentColor;
      border-radius: 950px 0px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      float: right;
      transition: all 1s ease;

      -webkit-animation: flicker-transparency 3.3s linear infinite both;
      animation: flicker-transparency 3.3s linear infinite both;

      &.transparent {
        background-color: rgba(255, 255, 255, 0) !important;
      }

      &.active {
        background: linear-gradient(
          -45deg,
          $mainColor,
          rgb(231, 255, 217),
          #fff,
          $accentColor
        );
        background-size: 400% 400%;
        animation: gradient 5s ease infinite;
      }

      &:hover {
        background: linear-gradient(
          -45deg,
          $mainColor,
          rgb(231, 255, 217),
          #fff,
          $accentColor
        );
        background-size: 400% 400%;
        animation: gradient 5s ease infinite;
      }
      @include mobile {
        align-items: flex-start;
      }

      img {
        height: 90%;
        -webkit-animation: text-flicker-in-glow 3s linear both;
        animation: text-flicker-in-glow 3s linear both;

        @include mobile {
          padding-top: 60px;
          height: 40%;
        }
      }
    }
  }
  .right {
    flex: 0.33;
    overflow: hidden;
    align-items: center;
    .imgContainer {
      width: 100%;
      height: 90vh;
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      @include mobile {
        justify-content: flex-start;
        padding-top: 30px;
      }

      img {
        width: 50%;
        -webkit-animation: flicker-3 2.5s linear infinite both;
        animation: flicker-3 2.5s linear infinite both;

        @include mobile {
          width: 50vw;
        }
      }
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: #141518;
      transition: all 0.2 ease;
      left: 50%;
      .icon {
        font-size: 50px;
        text-decoration: none;
        color: $accentColor;
        &.active {
          color: $mainColor;
        }
        -webkit-animation: flicker-3 2.5s linear infinite both;
        animation: flicker-3 2.5s linear infinite both;
      }
      .patreonpic {
        height: 80px;
        width: 80px;
      }
      &:hover {
        filter: drop-shadow(2px 2px 5px #141518);
        color: #ffffff;
      }
    }
  }
}

.flicker-3 {
  -webkit-animation: flicker-3 2.5s linear infinite both;
  animation: flicker-3 2.5s linear infinite both;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-2-16 14:52:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-3
 * ----------------------------------------
 */
@-webkit-keyframes flicker-3 {
  0%,
  100% {
    opacity: 1;
  }
  32.98% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  34.02% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.9% {
    opacity: 0;
  }
  35.92% {
    opacity: 1;
  }
  38.98% {
    opacity: 1;
  }
  39% {
    opacity: 0;
  }
  39.8% {
    opacity: 0;
  }
  39.82% {
    opacity: 1;
  }
  83.98% {
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  84.9% {
    opacity: 0;
  }
  84.92% {
    opacity: 1;
  }
}
@keyframes flicker-3 {
  0%,
  100% {
    opacity: 1;
  }
  32.98% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  34.02% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.9% {
    opacity: 0;
  }
  35.92% {
    opacity: 1;
  }
  38.98% {
    opacity: 1;
  }
  39% {
    opacity: 0;
  }
  39.8% {
    opacity: 0;
  }
  39.82% {
    opacity: 1;
  }
  83.98% {
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  84.9% {
    opacity: 0;
  }
  84.92% {
    opacity: 1;
  }
}

.text-flicker-in-glow {
  -webkit-animation: text-flicker-in-glow 4s linear both;
  animation: text-flicker-in-glow 4s linear both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-16 13:14:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
@-webkit-keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
}
@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45),
      0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55),
      0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6),
      0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25),
      0 0 100px rgba(255, 255, 255, 0.1);
  }
}

.flicker-transparency {
  -webkit-animation: flicker-transparency 3.3s linear infinite both;
  animation: flicker-transparency 3.3s linear infinite both;
}

@-webkit-keyframes flicker-transparency {
  0%,
  100% {
    background-color: $accentColor;
  }
  32.98% {
    background-color: $accentColor;
  }
  33% {
    background-color: transparent;
  }
  34% {
    background-color: transparent;
  }
  34.02% {
    background-color: $accentColor;
  }
  34.98% {
    background-color: $accentColor;
  }
  35% {
    background-color: transparent;
  }
  35.9% {
    background-color: transparent;
  }
  35.92% {
    background-color: $accentColor;
  }
  38.98% {
    background-color: $accentColor;
  }
  39% {
    background-color: transparent;
  }
  39.8% {
    background-color: transparent;
  }
  39.82% {
    background-color: $accentColor;
  }
  83.98% {
    background-color: $accentColor;
  }
  84% {
    background-color: transparent;
  }
  84.9% {
    background-color: transparent;
  }
  84.92% {
    background-color: $accentColor;
  }
}

@keyframes flicker-transparency {
  0%,
  100% {
    background-color: $accentColor;
  }
  32.98% {
    background-color: $accentColor;
  }
  33% {
    background-color: transparent;
  }
  34% {
    background-color: transparent;
  }
  34.02% {
    background-color: $accentColor;
  }
  34.98% {
    background-color: $accentColor;
  }
  35% {
    background-color: transparent;
  }
  35.9% {
    background-color: transparent;
  }
  35.92% {
    background-color: $accentColor;
  }
  38.98% {
    background-color: $accentColor;
  }
  39% {
    background-color: transparent;
  }
  39.8% {
    background-color: transparent;
  }
  39.82% {
    background-color: $accentColor;
  }
  83.98% {
    background-color: $accentColor;
  }
  84% {
    background-color: transparent;
  }
  84.9% {
    background-color: transparent;
  }
  84.92% {
    background-color: $accentColor;
  }
}

.puff-out-bottom {
  -webkit-animation: puff-out-bottom 0.9s cubic-bezier(0.165, 0.84, 0.44, 1)
    both;
  animation: puff-out-bottom 0.9s cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-24 7:45:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-bottom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-bottom {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
