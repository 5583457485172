@import '../../global.scss';

li{
    margin-bottom: 15px;
    
    a{
        font-size: 30px;
        color: $accentColor;
        text-decoration: none;
        &:hover{
            font-weight: 500;
            color: $header;
        }
    }
}