@import '../../global.scss';

.topbar {
  width: 100%;
  height: 70px;
  background-color: $accentColor;
  color: $mainColor;
  position: fixed;
  z-index: 3;
  scrollbar-width: none;
  transition: all 1.5s ease;
  overflow: hidden;
  @include mobile {
    bottom: 0;
  }
  .wrapper {
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 40px;
        font-weight: 500;
        text-decoration: none;
        color: $header;
        margin-right: 35px;
        transition: all 0.5s ease;

        &:hover {
          font-weight: 900;
        }

        @include mobile {
          font-size: 35px;
        }
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;

        a {
          color: $mainColor;
          text-decoration: none;
          &:hover{
            color: $accentColor;
          }
          span {
            font-weight: 500;
          }
        }

        @include mobile {
          display: none;
        }

        .icon {
          font-size: 18px;
          margin-right: 5px;
          color: $header;
        }

        .span {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .right {
      overflow: hidden;
      .toggler {
        width: 25px;
        height: 25px;
        display: flex;
        cursor: pointer;
        transition: all 1.5s ease;
        scrollbar-width: none;
        &:hover {
          color: $header;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: $accentColor;
    .wrapper{
      .left{
        .itemContainer{
          a{
            color: $accentColor;
          }
        }
      }
    }
    .toggler {
      transform: rotate(90deg);
      color: $header;
    }
  }
}
