@import '../../global.scss';

.contact {
  background-color: rgba($color: #000000, $alpha: 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: $accentColor;
  transition: all 3s ease;
  @include mobile {
    height: 95vh !important;
    margin-bottom: 5vh;
  }

  h1 {
    font-size: 40px;
    overflow: hidden;
    color: $header;

    .Typewriter {
      overflow: hidden;
      background-color: $accentColor;

      .Typewriter__cursor {
        color: $mainColor;
      }
    }

    @include mobile {
      font-size: 30px;
    }
  }

  .linksContainer {
    a#SoundCloud {
      .card {
        .iconContainer {
          .icon {
            height: 45px;
          }
        }
      }
    }

    a {
      text-decoration: none;
      font-size: 25px;
      color: $header;

      &:hover {
        font-weight: 500;
        .card {
          height: 170px;
          width: 170px;
          background-color: $accentColor;
          filter: alpha(opacity=60);
          font-size: 19px;
          @include mobile {
            height: 7.5vh;
            width: 100vw;
          }
          .iconContainer {
            .icon {
              fill: $header;
            }
          }
        }
      }

      .card {
        height: 120px;
        width: 120px;
        justify-content: center;
        align-items: center;
        background-color: $mainColor;
        opacity: 0.8;

        border: none;
        border-radius: 50%;
        transition: all 0.3s ease;
        font-size: 12px;
        text-align: center;
        color: whitesmoke;
        &:hover {
          color: rgb(90, 90, 90);
        }
        @include mobile {
          height: 7vh;
          border-radius: 0;
          flex-direction: row;
          width: 100vw;
          justify-content: space-around;
          font-size: 1.2rem;
        }
        .title {
          @include mobile {
            width: 200px;
          }
        }

        .iconContainer {
          filter: opacity(1);
          padding-bottom: 15px;
          @include mobile {
            padding-bottom: 0;
          }

          .icon {
            fill: $accentColor;
            height: 35px;
            transition: all 1s ease;
          }
        }
      }
    }
  }
}

.roll-in-blurred-top {
  -webkit-animation: roll-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1)
    both;
  animation: roll-in-blurred-top 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-24 9:6:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-720deg);
    transform: translateY(-800px) rotate(-720deg);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-720deg);
    transform: translateY(-800px) rotate(-720deg);
    -webkit-filter: blur(50px);
    filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
    transform: translateY(0) rotate(0deg);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-2-24 9:9:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
