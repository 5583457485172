@import '../../global.scss';


.menu{
    width: 33vw;
    height: 100vh;
    background-color: $accentColor;
    position: fixed;
    top: 0;
    right: -33vw;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transition: all 0.7s ease;
    

    @include mobile{
        width: 100vw;
        right: -100vw;
        text-align: center;
    }

    &.active{
        right: 0px;
        background-color: $mainColor
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 25px;
        font-weight: 300;
        color: $accentColor;
        width: 70%;
        display: inline;
        
        
    }
}