@import '../../global.scss';

.Hangman {
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  @include mobile {
    height: 95vh !important;
  }
  h1 {
    font-size: 40px;
    overflow: hidden;
    color: $header;

    .Typewriter {
      overflow: hidden;
      span {
        background-color: $accentColor;
      }

      .Typewriter__cursor {
        color: $mainColor;
        background-color: $accentColor;
      }
    }

    @include mobile {
      font-size: 30px;
    }
  }
  button {
    border: none;
    font-family: inherit;
    font-size: 1.5rem;
    cursor: pointer;
    width: 35px;
    margin: 3px 0px 5px 2px;
    letter-spacing: 1px;
    outline: none;
    background: #114d0f;
    color: #fff;
    box-shadow: 0 6px #535353;
    border-radius: 5px;
    &:hover {
      background-color: #8b8b8b;
    }
    &:disabled {
      background: #bdbdbd;
      color: #eeeeee;
      box-shadow: 0 6px #9e9e9e;
    }
  }

  .screen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    @include mobile {
      flex-direction: column;
    }
    .game {
      overflow: hidden;
      .stage {
        img {
          width: 350px;
        }
      }
      .score {
        height: 10vh;
        img{
          height: 100%;
        }
      }
    }
  }

  .controls,
  .result {
    width: 350px;
    overflow: hidden;
    @include mobile {
      height: 300px;
    }
  }
  .result {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 429px;
    @include mobile {
      font-size: 1.5rem;
      height: 300px;
      justify-content: center;
    }
  }

  .controls {
    .word {
      font-size: 2.5rem;
    }

    .btns {
      text-align: center;
      display: inline-block;
      margin-top: 0;
    }
  }

  .bold {
    font-size: 1.5rem;
    text-transform: capitalize;
    @include mobile {
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  #reset {
    width: 150px;
    margin: 0;
    margin-bottom: 10px;
    overflow: hidden;
  }
}

.rotating {
  -webkit-animation: rotating 5s reverse infinite;
  -moz-animation: rotating 5s reverse infinite;
  -ms-animation: rotating 5s reverse infinite;
  -o-animation: rotating 5s reverse infinite;
  animation: rotating 5s reverse infinite;
  overflow: hidden;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
