@import '../../global.scss';



.container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60%;
    width: 100vw;

    @include mobile{
        flex-wrap: wrap;
        height: 75vh;
    }

    
    .items{
        width: 100px;
        height: 100px;
        margin: 0px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.7s ease;
        overflow: hidden;

        @include mobile{
            width: 60vw;
            height: 30px;
            margin: none;
        }

        
        
        a{
           position: absolute;
           text-decoration: none;
           
            h3{
            
            font-size: 20px;
            cursor: pointer;
            transition: all 1s ease;
            overflow: hidden;


        } 
        }

        
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
            transition: all 0.8s ease;

        }

        &.active{
            width: 300px;
            height: 300px;
            opacity: 0.5;

            @include mobile{
                width: 250px;
                height: 250px;
            }
            
            h3{
                font-size: 30px;
                color: $header;
            }
            img{
                
                opacity: 0.5;
                z-index: 0;
            }
        }

        &:hover{
            width: 300px;
            height: 300px;
            opacity: 0.5;
            @include mobile{
                width: 250px;
                height: 250px;
            }
            
            h3{
                font-size: 30px;
                font-weight: 700;
                
            }
            img{
                
                opacity: 0.5;
                z-index: 0;
            }
        }
        

    }
    
}
